import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { get } from "lodash";
import HeaderVideo from "@components/header-video";
import SEO from "@components/seo";
import Layout from "@components/layout.js";
import About from "@components/about";
import Performances from "@components/performances";
import Instagram from "@components/instagram";
import Header from "@components/header/index.js";
import Video from "@components/video";
import Faces from "@components/faces";
import Press from "@components/press";
import Pong from "@components/pong";
import Sponsors from "@components/sponsors";
import ProductsGrid from "@components/products-grid";
import Scroller from "@components/scroller";

const ShopPage = ({ data }) => {
  const shows = get(data, "allStageItShow", []);
  const products = get(data, "products.edges", []);
  return (
    <Layout>
      <SEO title="Shop" />
      <Header isInterior={true} />
      <ProductsGrid products={products} />
    </Layout>
  );
};

export const showQuery = graphql`
  query shopQuery {
    products: allShopifyProduct(sort: { fields: title }) {
      edges {
        node {
          id
          title
          handle
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          variants {
            id
            title
            price
            availableForSale
            shopifyId
          }
          images {
            originalSrc
          }
        }
      }
    }
  }
`;

export default ShopPage;
